import { Moment } from 'moment'
import { createContext, useContext, useState, ReactNode } from 'react'

import api from '../../services/api'
import dataExporterAPI from "../../services/dataExporter";

import { useSnackbar } from '../SnackbarContext'

const BasicContext = createContext<I.BasicContext>({
  organizations: [],
  users: [],
  fetchOrganizations: () => new Promise(() => {}),
  fetchUsers: () => new Promise(() => {}),
  forceDocumentSignature: () => new Promise(() => {}),
  exportPaymentReport: () => new Promise(() => {}),
  exportContracts: () => new Promise(() => {}),
  fetchDataExporterJobList: () => new Promise(() => {}),
  generateLinkAWS: () => new Promise(() => {}),
  addUsersToOrganization: () => new Promise(() => {})
})

export const BasicProvider = ({ children }: { children: ReactNode }) => {
  const { showSnackbar } = useSnackbar()
  const [organizations, setOrganizations] = useState()
  const [users, setUsers] = useState()

  const handleFetchOrganizations = async () => {
    return api.get('/basic/organizations')
      .then((res) => setOrganizations(res.data))
      .catch((err) => console.log('Error on fetch basic organizations', err));
  }

  const handleFetchUsers = async () => {
    return api.get('/basic/users')
      .then((res) => setUsers(res.data))
      .catch((err) => console.log('Error on fetch basic users', err));
  }

  const handleForceDocumentSignature = async (id: string) => {
    return api.post('/basic/force_signature', { id })
      .then((res) => {
        showSnackbar('Procedimento realizado com sucesso', 'success');

        if (res?.data?.document) {
          return res.data.document;
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          return showSnackbar(err.response.data.message, 'error');
        }

        console.log('Error on force signature document basic', err);

        showSnackbar('Erro ao realizar procedimento', 'error');
      });
  }

  const handleExportPaymentReport = async (startDate: Moment, endDate: Moment) => {
    return api.post('/basic/export_payments', { startDate, endDate })
      .then((res) => {
        showSnackbar('Solicitação enviada com sucesso, você receberá um e-mail com link para download quando a exportação finalizar.', 'success');
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          return showSnackbar(err.response.data.message, 'error');
        }

        console.log('Error on export payments basic', err);

        showSnackbar('Erro ao exportar pagamentos.', 'error');
      });
  }

  const handleExportContracts = async ({
    organizationId: organization_id,
    emails,
    contracts,
    files,
    format,
    parties
  }: I.HandleExportContract): Promise<any> => {
    const body = {
      organization_id,
      emails,
      contracts, 
      files, 
      format,
      parties
    };

    return dataExporterAPI.post("/cksign/archive", body).then(() => {
      showSnackbar('A exportação está sendo processada.', 'success');
    }).catch((err) => {
      console.log("Data exporter error --> ", err);
      showSnackbar("Houve um erro ao exportar os contratos", "error");
    })
  }

  const handleFetchDataExporterJobList = async () => {
    try {
      const { data } = await dataExporterAPI.get("/cksign/archive");
      return data;
    } catch (error) {
      console.log("Data exporter error --> ", error);
      showSnackbar("Houve um erro ao listar as exportações", "error");
      return [];
    }
  }

  const handleGenerateLinkAWS = async (key: string) => {
    const body = { key, "bucket": "storage-dev-starter.contraktor.com.br" }
    try {
      const response = await dataExporterAPI.post("/generate-link", body);
      showSnackbar('Link gerado', 'success');
      return response;
    } catch (error) {
      console.log("Data exporter error --> ", error);
      showSnackbar("Houve um erro ao gerar novo link", "error");
      return null
    }
  }

  const handleAddUsersToOrganization = async (data: AddUsersData) => {
    await api.post('/basic/include_users', data)
      .then(({ data }) => showSnackbar(data.message, 'success'))
      .catch((err) => showSnackbar(err.message, 'success'))
  }

  return (
    <BasicContext.Provider
      value={{
        organizations,
        users,
        fetchOrganizations: handleFetchOrganizations,
        fetchUsers: handleFetchUsers,
        forceDocumentSignature: handleForceDocumentSignature,
        exportPaymentReport: handleExportPaymentReport,
        exportContracts: handleExportContracts,
        fetchDataExporterJobList: handleFetchDataExporterJobList,
        generateLinkAWS: handleGenerateLinkAWS,
        addUsersToOrganization: handleAddUsersToOrganization
      }}
    >
      {children}
    </BasicContext.Provider>
  )
}

export const useBasic = (): I.BasicContext => {
  const context = useContext(BasicContext)

  if (!context) {
    throw new Error('useBasic must be used within a <BasicProvider />')
  }

  return context
}

export default BasicContext
