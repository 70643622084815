/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useApp } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';

type Inputs = {
  email: string
  password: string
  remember: boolean
}

const Users = () => {
  const navigate = useNavigate();
  const { login } = useApp();
  const { handleSubmit, control, reset } = useForm<Inputs>({
    defaultValues: {
      remember: false,
      email: '',
      password: ''
    }
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    handleRememberChoice();
  }, []);

  const handleRememberChoice = async () => {
    const storedData = await localStorage.getItem('@contraktor-tech/superadmin');

    if (storedData) {
      const data = JSON.parse(storedData);

      reset({
        email: data.email,
        password: data.password,
        remember: data.remember
      })
    }
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);

    if (data.remember) {
      await localStorage.setItem('@contraktor-tech/superadmin', JSON.stringify(data));
    } else {
      await localStorage.removeItem('@contraktor-tech/superadmin');
    }

    await login(data)
      .then(({ success }) => {
        setLoading(false);

        if (success) {
          navigate('/', { replace: true });
          reset({});
        }
      })
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }}>
      <Paper sx={{ width: 400, padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: 'white' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>SuperADMIN</Typography>
          <Controller
            name="email"
            rules={{ required: true }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="E-mail"
                type="email"
                variant="outlined"
                fullWidth
                sx={{ marginTop: 1 }}
                error={Boolean(error)}
                helperText={error && 'Campo obrigatório'}
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            rules={{ required: true }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Senha"
                type="password"
                variant="outlined"
                fullWidth
                error={Boolean(error)}
                sx={{ marginTop: 1, marginBottom: 1 }}
                helperText={error && 'Campo obrigatório'}
                {...field}
              />
            )}
          />
          <Controller
            name="remember"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />} label="Lembrar-me" />
              </FormGroup>
            )}
          />
          <Button
            variant="contained"
            sx={{ marginTop: 1 }}
            fullWidth
            onClick={() => handleSubmit(onSubmit)()}
          >
            {loading ? <CircularProgress sx={{ color: 'white' }} size={24.5} /> : 'ENTRAR'}
          </Button>
        </form>
      </Paper>
    </Box>
  );
}

export default Users;
