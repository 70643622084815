/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

import { Moment } from 'moment';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { useBasic } from '../../../contexts/BasicContext';

const BasicExportPayments = () => {
  const { exportPaymentReport } = useBasic()

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  const onSubmit = async () => {
    if (!startDate) {
      return setStartDateError('Data inicial não informada.');
    }

    if (!endDate) {
      return setEndDateError('Data final não informada.');
    }

    if (startDate > endDate) {
      return setStartDateError('Data inicial maior que data final.');
    }

    setLoading(true);

    await exportPaymentReport(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

    setLoading(false);
    setStartDate(null);
    setEndDate(null);
  }

  return (
    <Box sx={{ margin: 1, width: '100%' }}>
      <Toolbar />
      <Typography variant="h6" style={{ margin: '0.5rem 0' }}>Exportar pagamentos</Typography>
      <Paper sx={{ padding: 1.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DesktopDatePicker
              label="Data inicial"
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
                setStartDateError('');
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  helperText={startDateError}
                  error={Boolean(startDateError)}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DesktopDatePicker
              label="Data final"
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
                setEndDateError('');
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  helperText={endDateError}
                  error={Boolean(endDateError)}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              onClick={onSubmit}
              fullWidth
              startIcon={loading && <CircularProgress sx={{ color: 'white' }}
                size={18}
              />}>
              Exportar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default BasicExportPayments;