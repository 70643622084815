import React from 'react'
import { Navigate } from 'react-router-dom'

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import DrawerMenu from '../Drawer';
import Header from '../Header';

interface Props {
  children: React.ReactElement
}

const ProtectedRoute = ({ children }: Props): React.ReactElement => {
  const token = localStorage.getItem('SA_TOKEN');

  if (!token) {
    return <Navigate to="/login" replace />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <DrawerMenu />
      {children}
    </Box>
  );
}

export default ProtectedRoute;