import { Typography } from "@mui/material";

const ContainerInformation = ({ title, info, style, children }: any) => {
  return (
    <div style={{ margin: "1.25rem 0px", ...style }}>
      <Typography variant="body2" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="body2">{info || children}</Typography>
    </div>
  );
};

export default ContainerInformation;
