import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import AsyncButton from "../../../components/AsyncButton";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import { useBasic } from "../../../contexts/BasicContext";

interface IExportData {
  open: boolean;
  onClose: () => void;
}

type formatTypeValue = "csv" | "xlsx";

const fileTypes = [
  { label: "Excel", value: "xlsx" },
  { label: "CSV", value: "csv" },
];

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const isValidUUID = (organization: string) => {
  const uuidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidPattern.test(organization);
};

const ExportData: React.FC<IExportData> = ({ open, onClose }: IExportData) => {
  const { exportContracts } = useBasic();

  const { showSnackbar } = useSnackbar();

  const [organizationId, setOrganizationId] = React.useState<string>("");
  const [isValidOrganization, setIsValidOrganization] = React.useState<
    boolean | null
  >(null);

  const [email, setEmail] = React.useState<string>("");
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);

  const [isContractsChecked, setIsContractsChecked] =
    React.useState<boolean>(false);
  const [isFilesChecked, setFilesChecked] = React.useState<boolean>(false);
  const [isPartiesChecked, setIsPartiesChecked] =
    React.useState<boolean>(false);

  const [selectedFormat, setSelectedFormat] = React.useState<formatTypeValue>(
    fileTypes[0].value as formatTypeValue
  );

  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (
      (!isContractsChecked && !isFilesChecked && !isPartiesChecked) ||
      organizationId === null ||
      organizationId === "" ||
      email === "" ||
      !isValidEmail ||
      !isValidOrganization
    ) {
      console.log("disabled")
      setIsButtonDisabled(true);
    } else {
      console.log("enabled")
      setIsButtonDisabled(false);
    }
  }, [
    organizationId,
    email,
    isValidEmail,
    isContractsChecked,
    isFilesChecked,
    isPartiesChecked,
    isValidOrganization
  ]);

  const handleClickExport = async () => {
    try {
      await exportContracts({
        contracts: isContractsChecked,
        emails: [email],
        files: isFilesChecked,
        organizationId,
        parties: isPartiesChecked,
        format: selectedFormat,
      });
      onCloseDialog();
    } catch (error: any) {
      showSnackbar(error.message, "error");
    }
  };

  const handleChangeOrgId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text: string = event.target.value;
    setOrganizationId(text);
  };

  const validateOrganization = () => {
    const isvalid = isValidUUID(organizationId)
    console.log({isvalid})
    setIsValidOrganization(isvalid);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setEmail(text);

    const _isValidEmail: boolean = Boolean(validateEmail(text));
    setIsValidEmail(_isValidEmail);
  };

  const handleChangeFileType = (event: any) => {
    setSelectedFormat(event.target.value);
  };

  const onCloseDialog = () => {
    //clear state
    setOrganizationId("");
    setEmail("");
    setIsValidEmail(false);
    setIsContractsChecked(false);
    setFilesChecked(false);
    setIsPartiesChecked(false);

    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle>Exportar dados</DialogTitle>
        <DialogContent>
          <div style={{ width: "20rem" }}>
            <div style={{ margin: "12px 0px" }}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="organizationIdInput"
                  label="ID da organização"
                  variant="outlined"
                  onChange={handleChangeOrgId}
                  error={
                    isValidOrganization !== null ? !isValidOrganization : false
                  }
                  helperText={"ID da organização deve ser UUID"}
                  onBlur={validateOrganization}
                />
              </FormControl>
            </div>

            <div style={{ margin: "12px 0px" }}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="emailInput"
                  label="Email de envio"
                  variant="outlined"
                  onChange={handleChangeEmail}
                />
              </FormControl>
            </div>

            <div>
              <FormControl style={{ width: "-webkit-fill-available" }}>
                <InputLabel id="SelectFormatInputLabel-auto-width">
                  Formato do arquivo
                </InputLabel>
                <Select
                  labelId="SelectFormatInputLabel"
                  id="SelectFormatInput"
                  value={selectedFormat}
                  label="Formato do arquivo"
                  onChange={handleChangeFileType}
                >
                  {fileTypes.map((fileType) => (
                    <MenuItem value={fileType.value}>{fileType.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <div>
                <span>Contratos: </span>
                <Switch
                  onChange={(e) => setIsContractsChecked(e.target.checked)}
                />
              </div>

              <div>
                <span>Arquivos: </span>
                <Switch onChange={(e) => setFilesChecked(e.target.checked)} />
              </div>

              <div>
                <span>Participantes: </span>
                <Switch
                  onChange={(e) => setIsPartiesChecked(e.target.checked)}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "28px",
            }}
          >
            <Button
              onClick={onCloseDialog}
              variant="text"
              style={{ marginRight: "8px" }}
            >
              Cancelar
            </Button>
            <AsyncButton
              onClick={handleClickExport}
              variant="contained"
              disabled={isButtonDisabled}
            >
              Exportar
            </AsyncButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExportData;
