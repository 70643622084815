import {
  Box,
  Button,
  Checkbox,
  Chip,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import DialogExport from "./DialogExport";
import { useAdvanced } from "../../../contexts/AdvancedContext";
import DialogNewUrlExport from "./DialogNewUrlExport";

const rowsPerPage = 10;

const ExportData: React.FC = () => {
  const { fetchDataExporterJobList, exportContracts, generateLinkAWS } = useAdvanced();

  const [isOpenExportDialog, setIsOpenExportDialog] = React.useState<boolean>(false);

  const [isDialogLinkOpen, setIsDialogLinkOpen] = React.useState<boolean>(false);
  const [exportLinkGenerated, setExportLinkGenerated] = React.useState<string>("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowInfo, setRowInfo] = React.useState(null);

  const [listJobs, setListJobs] = React.useState<any[]>([]);

  const [page, setPage] = React.useState<number>(0);

  const getListJobs = async () => {
    const _listJobs = await fetchDataExporterJobList();
    setListJobs(_listJobs);
  };

  React.useEffect(() => {
    getListJobs();
    // eslint-disable-next-line
  }, []); 

  const formatDate = (date: string | null) => {
    if (date === null) return "";

    const _date = new Date(date);

    const formated_date = new Intl.DateTimeFormat("pt-br", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    }).format(_date);
    return formated_date;
  };

  const translateStatusJob = (status: string): { text: string; color: any } => {
    const translateObject: any = {
      finished: { text: "Finalizado", color: "success" },
      queued: { text: "Em fila", color: "info" },
      started: { text: "Iniciado", color: "primary" },
      failed: { text: "Erro", color: "error" },
    };
    return translateObject[status];
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setRowInfo(null);
  };

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setAnchorEl(event.currentTarget);
    setRowInfo(row);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage < 0 ? 0 : newPage);
  };

  const handleClickTryAgainExportContract = async () => {
    const { contracts, files, parties, emails, format, organization_id: organizationId } =
      rowInfo!["request"];

    await exportContracts({
      contracts,
      files,
      parties,
      emails,
      format,
      organizationId
    });
  };

  const handleClickGenerateLink = async (row: any) => {
    const key = row["result"]["zip_s3_key"]; 

    const { data: newUrl, status } = await generateLinkAWS(key);
    
    if (status === 200) {
      setExportLinkGenerated(newUrl);
      setIsDialogLinkOpen(true);
    }
  };

  const renderOrigin = (origin: 'default' | 'high'): string => {
    if (origin === 'default') return 'SuperADMIN';

    return 'Plataforma';
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {rowInfo && "status" in rowInfo && rowInfo["status"] === "failed" && (
          <MenuItem onClick={handleClickTryAgainExportContract}>
            Tentar novamente
          </MenuItem>
        )}

        <MenuItem onClick={() => handleClickGenerateLink(rowInfo)} disabled={rowInfo !== null && rowInfo?.["status"] !== "finished" ? true : false}>
          Gerar novo link
        </MenuItem>
      </Menu>

      {isOpenExportDialog && <DialogExport
        open={isOpenExportDialog}
        onClose={() => setIsOpenExportDialog(false)}
      />}


      {isDialogLinkOpen && <DialogNewUrlExport
        open={isDialogLinkOpen}
        onClose={() => {
          setIsDialogLinkOpen(false);
          setExportLinkGenerated("");
        }}
        link={exportLinkGenerated}
      />}
      
      <Box sx={{ margin: 1, width: "100%" }}>
        <Toolbar />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "1rem 0",
          }}
        >
          <Typography variant="h6">Últimas exportações realizadas</Typography>
          <Button
            onClick={() => setIsOpenExportDialog(true)}
            variant="contained"
          >
            Exportar dados
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Organização</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Formato</TableCell>
                <TableCell>Contratos</TableCell>
                <TableCell>Pessoas</TableCell>
                <TableCell>Arquivos</TableCell>
                <TableCell>Intervalo de data</TableCell>
                <TableCell>Origem</TableCell>
                <TableCell>Criado em</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {listJobs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => (
                <TableRow key={key}>
                  <TableCell>{row.request.organization_id}</TableCell>
                  <TableCell>
                    <Chip
                      label={translateStatusJob(row.status).text}
                      color={translateStatusJob(row.status).color}
                    />
                  </TableCell>
                  <TableCell>{row.request.format}</TableCell>
                  <TableCell>
                    <Checkbox disabled checked={row.request.contracts} />
                  </TableCell>
                  <TableCell>
                    <Checkbox disabled checked={row.request.parties} />
                  </TableCell>
                  <TableCell>
                    <Checkbox disabled checked={row.request.files} />
                  </TableCell>
                  <TableCell>
                    {formatDate(row.request.filter.begin_date)} -{" "}
                    {formatDate(row.request.filter.end_date)}
                  </TableCell>
                  <TableCell>
                    {renderOrigin(row.origin)}
                  </TableCell>
                  <TableCell>
                    {formatDate(row.created_at)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleOpenMenu(e, row)}>
                      <Icon>settings</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={3}
                  count={listJobs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ExportData;
