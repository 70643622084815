import { Icon, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import SettingsIcon from '@mui/icons-material/Settings';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import api from "../../../services/api";
import { formatDate } from "../../../utils/date";

const Organizations: React.FC = () => {
	const { showSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [organizations, setOrganizations] = useState<any>([]);
	const [totalOrganizations, setTotalOrganizations] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [searchText, setSearchText] = useState<string>("");

	const fetchOrganizations = async () => {
		try {
			const { data } = await api.get("/advanced/organizations", {
				params: { page, rows: 10, search: searchText }
			});
			setOrganizations(data.organizations);

			if("total" in data.pagination) {
				setTotalOrganizations(data.pagination.total)
			}

		} catch (error) {
			showSnackbar('Erro ao listar organizações.', 'error');
			return []
		}
	}

	useEffect(() => {
		fetchOrganizations();
		// eslint-disable-next-line
	}, [page]);

	const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

	const handleSearch = async () => {
		await fetchOrganizations();
		setPage(0);
	}

	return (
		<Box
			sx={{ margin: 1, width: "100%" }}
		>
			<Toolbar />
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography variant="h6" style={{ margin: '0.5rem 0 1rem 0' }}>Organizações</Typography>
				<TextField
					size="small"
					placeholder="Pesquisar por ID ou Nome"
					onChange={(e) => setSearchText(e.target.value)}
					onKeyDown={(e) => e.key === "Enter" && handleSearch()}
					InputProps={{
						startAdornment: <Icon color="primary" sx={{ marginRight: 1 }}>search</Icon>
					}}
				/>
        </Box>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Nome</TableCell>
							<TableCell>Ativo</TableCell>
							<TableCell>Criado em</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{organizations.map((org: any) => (
							<TableRow key={org.id}>
								<TableCell>{org.id}</TableCell>
								<TableCell>{org.name}</TableCell>
								<TableCell>{org.active ? <Icon color="success">check</Icon> : <Icon color="error">not_interested</Icon>}</TableCell>
								<TableCell>{formatDate(org.inserted_at)}</TableCell>
								<TableCell
									onClick={() => navigate(`/advanced/organization/${org.id}`)}
									style={{ cursor: "pointer" }}
									align="right"
								>
									<IconButton><SettingsIcon /></IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>

					<TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={3}
                  count={totalOrganizations}
                  rowsPerPage={10}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default Organizations;
