/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Toolbar from '@mui/material/Toolbar';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { useAdvanced } from '../../../contexts/AdvancedContext';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { IconButton, Icon, Menu, MenuItem, TextField, CircularProgress } from '@mui/material';
import useDebounce from '../../../utils/useDebounce';

const AdvancedUsers = () => {
  const { fetchUsers, userLooping, users } = useAdvanced();
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const debouncedQuery: string = useDebounce(query, 3000);

  useEffect(() => {
    if (query) {
      return setLoading(true)
    } 

    setLoading(false)
  }, [query])

  useEffect(() => {
    handleFetchData()
  }, [debouncedQuery, rowsPerPage, page])

  useEffect(() => {
    handleFetchData()
  }, []);

  const handleFetchData = async () => {
    if (debouncedQuery) {
      await fetchUsers(10, 0, debouncedQuery).then(() => {
        setLoading(false)
        setPage(0)
      })
    } else {
      await fetchUsers(rowsPerPage, page).then(() => setLoading(false))
    }
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    users && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.pagination.total) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    console.log('ewn', newPage);
    setPage(newPage < 0 ? 0 : newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, userEmail: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserEmail(userEmail);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUserEmail('');
  };

  const handleUserLooping = async () => {
    if (selectedUserEmail) {
      handleClose()

      await userLooping(selectedUserEmail)
    }
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleUserLooping}>Looping</MenuItem>
      </Menu>
      <Box sx={{ margin: 1, width: '100%' }}>
        <Toolbar />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" style={{ margin: '0.5rem 0 1rem 0' }}>Usuários dos planos avançados</Typography>
          <TextField
            size="small"
            placeholder="Pesquisar por e-mail.."
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: loading ? <CircularProgress size={24} sx={{ marginRight: 1.5 }} color="primary" /> : <Icon color="primary" sx={{ marginRight: 1 }}>search</Icon>
            }}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="right">E-mail</TableCell>
                <TableCell align="right">Criado em</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.data.map((row, key) => (
                <TableRow key={key}>
                  <TableCell>
                    {row.first_name.concat(' ').concat(row.last_name)}
                  </TableCell>
                  <TableCell align="right">
                    {row.email}
                  </TableCell>
                  <TableCell align="right">
                    {row.inserted_at.toString()}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={(e) => handleClick(e, row.email)}>
                      <Icon>settings</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={3}
                  count={(users && users.pagination.total) || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default AdvancedUsers;
