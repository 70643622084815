import { useEffect, useState } from "react";

import {
  Button,
  Icon,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import { useOrganization } from "../../../../../../contexts/OrganizationContext";

const FormsOrganization: React.FC = () => {
  const { forms, updateForm, searchTextForms } = useOrganization();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [rowSelected, setRowSelected] = useState<any>({});
  const [filteredList, setFilteredList] = useState<any>([]);

  const handleClick = (e: any, row: any) => {
    setAnchorEl(e.currentTarget);
    setRowSelected(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRowSelected({});
  };

  const handleClickActivateCustomForm = async () => {
    const id = rowSelected["id"];
    await updateForm(id, true);
  };

  const handleClickDeactivateCustomForm = async () => {
    const id = rowSelected["id"];
    await updateForm(id, false);
  };

  const searchInForms = () => {
    const list = forms.filter((el: any) => el.id.includes(searchTextForms));

    if (searchTextForms === "") {
      setFilteredList(forms);
    } else {
      setFilteredList(list);
    }
  };

  useEffect(() => {
    searchInForms();
    // eslint-disable-next-line
  }, [forms, searchTextForms]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (Array.isArray(forms) && forms.length > 0) {
    return (
      <>
        {/* <Box sx={{ m: 1 }}>
        <TextField
          size="small"
          placeholder="Pesquisar por ID"
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            startAdornment: <Icon color="primary" sx={{ marginRight: 1 }}>search</Icon>
          }}
        />
      </Box> */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell>Grupo</TableCell>
              <TableCell>Aceita Resp. Formulário?</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList.map((el: any) => (
              <TableRow key={el.id}>
                <TableCell>{el.id}</TableCell>
                <TableCell>
                  {el.active ? (
                    <Icon color="success">check</Icon>
                  ) : (
                    <Icon color="error">not_interested</Icon>
                  )}
                </TableCell>
                <TableCell>{el.group_name}</TableCell>
                <TableCell>
                  {el.custom ? (
                    <Icon color="success">check</Icon>
                  ) : (
                    <Icon color="error">not_interested</Icon>
                  )}
                </TableCell>
                <TableCell style={{ cursor: "pointer" }} align="right">
                  <IconButton onClick={(e) => handleClick(e, el)}>
                    <SettingsIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 0.5 }}>
            {"custom" in rowSelected && rowSelected["custom"] ? (
              <Button variant="text" onClick={handleClickDeactivateCustomForm}>
                Desativar
              </Button>
            ) : (
              <Button variant="text" onClick={handleClickActivateCustomForm}>
                Ativar
              </Button>
            )}
          </Typography>
        </Popover>
      </>
    );
  } else {
    return (
      <>
        <Typography sx={{ p: 2 }} variant="body2">Essa organização não possui formulários.</Typography>
      </>
    );
  }
};

export default FormsOrganization;
