import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import AsyncButton from "../../../../../components/AsyncButton";

type DialogDiscardChangesProps = {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
};

const DialogDiscardChanges = ({
  open,
  onClose,
  onContinue,
}: DialogDiscardChangesProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Alterações de funcionalidades da organização</DialogTitle>
      <DialogContent>Deseja descartar as alterações feitas?</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <AsyncButton variant="contained" onClick={onContinue}>
          Descartar
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDiscardChanges;
