import { createContext, useContext, useState, ReactNode } from 'react'

import api from '../../services/api'
import dataExporterAPI from "../../services/dataExporter";

import { useSnackbar } from '../SnackbarContext'

const AdvancedContext = createContext<I.AdvancedContext>({
  organizations: [],
  fetchOrganizations: () => new Promise(() => {}),
  fetchOrganization: () => new Promise(() => {}),
  fetchUsers: () => new Promise(() => {}),
  userLooping: () => new Promise(() => {}),
  exportContracts: () => new Promise(() => {}),
  fetchDataExporterJobList: () => new Promise(() => {}),
  generateLinkAWS: () => new Promise(() => {}),
  forceMigrate: () => new Promise(() => {})
})

export const AdvancedProvider = ({ children }: { children: ReactNode }) => {
  const { showSnackbar } = useSnackbar()

  const [organizations, setOrganizations] = useState([])
  const [users, setUsers] = useState({ data: [], pagination: {} })

  const handleFetchOrganizations = async () => {
    return api.get('/advanced/organizations')
      .then((res) => setOrganizations(res.data))
      .catch((err) => console.log('Error on fetch advanced organizations', err));
  }

  const handleFetchOrganization = async (id: string) => {
    return api.get(`/advanced/organization/${id}`)
      .then((res) => res.data)
      .catch((err) => console.log('Error on fetch advanced organization by id', err));
  }

  const handleFetchUsers = async (rows: number = 10, page: number = 1, email?: string) => {
    return api.get('/advanced/users', {  params: { email, rows, page }})
      .then((res) => setUsers(res.data))
      .catch((err) => {
        console.log('Error on fetch advanced users', err)
        showSnackbar('Erro ao carregar usuários.', 'error');
      });
  }

  const handleUserLooping = async (email: string) => {
    return api.post('/advanced/looping', { email })
      .then(() => {
        showSnackbar('Procedimento realizado com sucesso.', 'success')
      })
      .catch((err) => {
        console.log('Error on user looping', err)
        showSnackbar('Erro ao realizar procedimento usuário.', 'error');
      });
  }

  const handleExportContracts = async ({
    organizationId: organization_id,
    emails,
    contracts,
    files,
    format,
    parties,
    userId
  }: I.HandleExportContract): Promise<any> => {
    const body = {
      organization_id,
      emails,
      contracts, 
      files, 
      format,
      parties,
      user_id: userId
    };
    
    return dataExporterAPI.post("/archive", body).then(() => {
      showSnackbar('A exportação está sendo processada.', 'success');
    }).catch((err) => {
      console.log("Data exporter error --> ", err);
      showSnackbar("Houve um erro ao exportar os contratos", "error");
    })
  }

  const handleFetchDataExporterJobList = async () => {
    try {
      const { data } = await dataExporterAPI.get("/archive");
      return data;
    } catch (error) {
      console.log("Data exporter error --> ", error);
      showSnackbar("Houve um erro ao listar as exportações", "error");
      return [];
    }
  }

  const handleGenerateLinkAWS = async (key: string) => {
    const body = { key }
    try {
      const response = await dataExporterAPI.post("/generate-link", body);
      showSnackbar('Link gerado', 'success');
      return response;
    } catch (error) {
      console.log("Data exporter error --> ", error);
      showSnackbar("Houve um erro ao gerar novo link", "error");
      return null
    }
  }

  const handleForceMigrate = async (email: string) => {
    await api.post(`/advanced/force-migrate`, { email })
      .then(() => {
        showSnackbar('Usuário migrado com sucesso, um link de redefinição de senha foi enviado para o seu e-mail', 'success');
      })
      .catch((err) => {
        let message = "Erro ao realizar migração, tente novamente ou entre em contato com a T.I";

        if (err?.response?.data?.message) {
          message = err.response.data.message;
        }

        showSnackbar(message, "error");
      });
  }

  return (
    <AdvancedContext.Provider
      value={{
        organizations,
        users,
        fetchOrganizations: handleFetchOrganizations,
        fetchOrganization: handleFetchOrganization,
        fetchUsers: handleFetchUsers,
        userLooping: handleUserLooping,
        exportContracts: handleExportContracts,
        fetchDataExporterJobList: handleFetchDataExporterJobList,
        generateLinkAWS: handleGenerateLinkAWS,
        forceMigrate: handleForceMigrate
      }}
    >
      {children}
    </AdvancedContext.Provider>
  )
}

export const useAdvanced = (): I.AdvancedContext => {
  const context = useContext(AdvancedContext)

  if (!context) {
    throw new Error('useAdvanced must be used within a <AdvancedProvider />')
  }

  return context
}

export default AdvancedContext
