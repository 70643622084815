import { Icon, IconButton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';

const Header = () => {
  const { logout } = useApp()
  const navigate = useNavigate()

  const handleLogout = async () => {
    await logout();
    navigate('/login', { replace: true });
  }

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" noWrap component="div">
          SuperADMIN
        </Typography>
        <IconButton onClick={handleLogout}>
          <Icon sx={{ color: 'white' }}>logout</Icon>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
