import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const items = {
  advanced: [
    {
      icon: <Icon>account_circle</Icon>,
      text: 'Usuários',
      path: '/advanced/users',
    },
    {
      icon: <BusinessIcon />,
      text: 'Organizações',
      path: '/advanced/organizations'
    },
    {
      icon: <Icon>cloud_upload</Icon>,
      text: 'Importar dados',
      path: '/advanced/import-data'
    },
    {
      icon: <Icon>cloud_download</Icon>,
      text: 'Exportar dados',
      path: '/advanced/export-data'
    },
    {
      icon: <Icon>account_circle</Icon>,
      text: 'Migrar usuário',
      path: '/advanced/force-migrate'
    },
  ],
  basic: [
    {
      icon:  <Icon>bug_report</Icon>,
      text: 'Doc. sem comprovante',
      path: '/basic/force-signature',
    },
    {
      icon:  <Icon>description</Icon>,
      text: 'Exportar pagamentos',
      path: '/basic/export-payments',
    },
    {
      icon: <Icon>cloud_download</Icon>,
      text: 'Exportar dados',
      path: '/basic/export-data'
    },
    {
      icon: <Icon>account_circle</Icon>,
      text: 'Adicionar usuário em organização',
      path: '/basic/add-user-to-organization'
    },
  ],
  system: [
    {
      icon: <Icon>account_circle</Icon>,
      text: 'Usuários',
      path: '/users',
    }
  ]
}

const DrawerMenu = () => {
  const navigate = useNavigate()

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItem>
            <ListItemText primary="Planos avançados" />
          </ListItem>
          {items['advanced'].map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton onClick={() => navigate(item.path)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
          <Divider />
          <ListItem>
            <ListItemText primary="Planos básicos" />
          </ListItem>
          {items['basic'].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton onClick={() => navigate(item.path)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
          <Divider />
          <ListItem>
            <ListItemText primary="SuperADMIN"  />
          </ListItem>
          {items['system'].map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton onClick={() => navigate(item.path)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default DrawerMenu;
