import axios, { AxiosError } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const handleError = (err: AxiosError) => {
  if (err?.response?.status === 401) {
    window.location.replace('/login');
  }

  return Promise.reject(err);
}

api.interceptors.response.use((response) => response, handleError)

export default api;