import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { useOrganization } from "../../../../../../contexts/OrganizationContext";
import { formatDate } from "../../../../../../utils/date";
import ContainerInformation from "../../../shared/ContainerInformation";

const engines: I.engine[] = ["standard", "certificate", "hybrid"];

const DetailsOrganization: React.FC = () => {
  const {
    organization,
    organizationName,
    setOrganizationName,
    isOrganizationActive,
    setIsOrganizationActive,
    logoOrganization,
    setLogoOrganization,
    availableEngines,
    setAvailableEngines,
    contractSeqNumber,
    setContractSeqNumber,
    contractSeqYear,
    setContractSeqYear,
  } = useOrganization();

  return (
    <div style={{ padding: "24px 0 0 0" }}>
      <TextField
        label="Nome"
        variant="outlined"
        value={organizationName}
        onChange={(e) => setOrganizationName(e.target.value)}
      />

      <ContainerInformation title="Ativo">
        <Switch
          checked={isOrganizationActive}
          onChange={(e) => setIsOrganizationActive(e.target.checked)}
        />
      </ContainerInformation>

      <TextField
        label="Logo"
        variant="outlined"
        value={logoOrganization}
        onChange={(e) => setLogoOrganization(e.target.value)}
      />

      <ContainerInformation>
        <FormControl sx={{ width: 230 }}>
          <InputLabel id="engines-checkbox-label">Engines</InputLabel>
          <Select
            labelId="engines-checkbox-label"
            id="id-engines"
            multiple
            value={availableEngines}
            onChange={(e) => {
              const _engines: I.engine[] = (
                Array.isArray(e.target.value)
                  ? e.target.value
                  : [e.target.value]
              ).map((value) => value as I.engine);
              setAvailableEngines(_engines);
            }}
            input={<OutlinedInput label="Engines" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {Array.isArray(availableEngines) &&
              availableEngines.length > 0 &&
              engines.map((name: I.engine) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={availableEngines.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </ContainerInformation>

      <ContainerInformation>
        <TextField
          label="Número sequencial do contrato"
          variant="outlined"
          value={Number(contractSeqNumber)}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => setContractSeqNumber(Number(e.target.value))}
        />
      </ContainerInformation>

      <ContainerInformation>
        <TextField
          label="Ano sequencial do contrato"
          variant="outlined"
          value={Number(contractSeqYear)}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => setContractSeqYear(Number(e.target.value))}
        />
      </ContainerInformation>

      <ContainerInformation
        title="Addons"
        info={organization.addons.join(", ")}
      />

      <ContainerInformation
        title="Criada em"
        info={formatDate(organization.inserted_at)}
      />

      <ContainerInformation
        title="Atualizado em"
        info={formatDate(organization.updated_at)}
        style={{ padding: 0 }}
      />
    </div>
  );
};

export default DetailsOrganization;
