import { createContext, useContext, useState, ReactNode, forwardRef } from 'react'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

const SnackbarContext = createContext<I.SnackbarContext>({
  showSnackbar: () => new Promise(() => {}),
})

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleShowSnackbar = async (message: string, type: AlertColor) => {
    setMessage(message);
    setType(type);
    return setOpen(true);
  }

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar: handleShowSnackbar
      }}
    >
      {children}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = (): I.SnackbarContext => {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar must be used within a <SnackbarProvider />')
  }

  return context
}

export default SnackbarContext
