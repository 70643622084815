import { useState, useEffect } from 'react'

export default function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    let handler: any
    if (value.length > 0) {
      handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
    } else {
      setDebouncedValue(value)
    }

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
