import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import AsyncButton from "../../../components/AsyncButton";
import { useAdvanced } from "../../../contexts/AdvancedContext";
import { useSnackbar } from "../../../contexts/SnackbarContext";
import api from "../../../services/api";

const getIdAdministrator = async (orgId: number | string) => {
  try {
    const { data } = await api.get(`/advanced/organization/administrator/${orgId}`);
    return Number(data.user_id);
  } catch (error) {
    throw new Error("Não foi possível obter o ID do administrador da organização.");
  }
}

interface IExportData {
  open: boolean;
  onClose: () => void;
}

type formatTypeValue = "csv" | "xlsx";

const fileTypes = [
  { label: "Excel", value: "xlsx" },
  { label: "CSV", value: "csv" },
];

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const ExportData: React.FC<IExportData> = ({ open, onClose }: IExportData) => {
  const { exportContracts } = useAdvanced();

  const { showSnackbar } = useSnackbar() 

  const [organizationId, setOrganizationId] = React.useState<number>(0);

  const [email, setEmail] = React.useState<string>("");
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);

  const [isContractsChecked, setIsContractsChecked] =
    React.useState<boolean>(false);
  const [isFilesChecked, setFilesChecked] = React.useState<boolean>(false);
  const [isPartiesChecked, setIsPartiesChecked] =
    React.useState<boolean>(false);

  const [selectedFormat, setSelectedFormat] = React.useState<formatTypeValue>(
    fileTypes[0].value as formatTypeValue
  );

  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (
      (isContractsChecked || isFilesChecked || isPartiesChecked) &&
      organizationId !== null &&
      organizationId > 0 &&
      email !== "" &&
      isValidEmail === true
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    organizationId,
    email,
    isValidEmail,
    isContractsChecked,
    isFilesChecked,
    isPartiesChecked,
  ]);

  const handleClickExport = async () => {
    try {
      const admId = await getIdAdministrator(organizationId);
      
      await exportContracts({
        contracts: isContractsChecked,
        emails: [email],
        files: isFilesChecked,
        organizationId,
        parties: isPartiesChecked,
        format: selectedFormat,
        userId: admId
      });
      onCloseDialog();
    } catch (error: any) {
      showSnackbar(error.message, "error")
    }

  };

  const handleChangeOrgId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text: string = event.target.value;
    let _organizationId = 0;

    try {
      _organizationId = parseInt(text);
    } catch (error) {
      console.log(error);
    }

    setOrganizationId(_organizationId);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setEmail(text);

    const _isValidEmail: boolean = Boolean(validateEmail(text));
    setIsValidEmail(_isValidEmail);
  };

  const handleChangeFileType = (event: any) => {
    setSelectedFormat(event.target.value);
  };

  const onCloseDialog = () => {
    //clear state
    setOrganizationId(0);
    setEmail("");
    setIsValidEmail(false);
    setIsContractsChecked(false);
    setFilesChecked(false);
    setIsPartiesChecked(false);

    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle>Exportar dados</DialogTitle>
        <DialogContent>
          <div style={{ width: "20rem" }}>
            <div style={{ margin: "12px 0px" }}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="organizationIdInput"
                  label="ID da organização"
                  variant="outlined"
                  type="number"
                  onChange={handleChangeOrgId}
                />
              </FormControl>
            </div>

            <div style={{ margin: "12px 0px" }}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="emailInput"
                  label="Email de envio"
                  variant="outlined"
                  onChange={handleChangeEmail}
                />
              </FormControl>
            </div>

            <div>
                <FormControl style={{ width: "-webkit-fill-available" }}>
                <InputLabel id="SelectFormatInputLabel-auto-width">Formato do arquivo</InputLabel>
                  <Select
                    labelId="SelectFormatInputLabel"
                    id="SelectFormatInput"
                    value={selectedFormat}
                    label="Formato do arquivo"
                    onChange={handleChangeFileType}
                  >
                    {fileTypes.map((fileType) => (
                      <MenuItem value={fileType.value}>
                        {fileType.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <div>
                <span>Contratos: </span>
                <Switch
                  onChange={(e) => setIsContractsChecked(e.target.checked)}
                />
              </div>

              <div>
                <span>Arquivos: </span>
                <Switch onChange={(e) => setFilesChecked(e.target.checked)} />
              </div>

              <div>
                <span>Participantes: </span>
                <Switch
                  onChange={(e) => setIsPartiesChecked(e.target.checked)}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "28px",
            }}
          >
            <Button 
              onClick={onCloseDialog}
              variant="text"
              style={{marginRight: "8px"}}
            >
              Cancelar
            </Button>
            <AsyncButton
              onClick={handleClickExport}
              variant="contained"
              disabled={isButtonDisabled}
            >
              Exportar
            </AsyncButton>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExportData;
