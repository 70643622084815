/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useApp } from '../../../../../contexts/AppContext';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

type Props = {
  open: boolean
  id?: string
  onClose: () => void
}

type Inputs = {
  name: string
  email: string
  password: string
}

const UserDetails = ({ open, onClose }: Props) => {
  const { createUser } = useApp();
  const { handleSubmit, control, reset } = useForm<Inputs>()
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true)

    await createUser(data)

    setLoading(false)
    reset({})
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Novo usuário</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Nome" 
                    type="name"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 1 }}
                    error={Boolean(error)}
                    helperText={error && 'Campo obrigatório'}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="E-mail" 
                    type="email"
                    variant="outlined"
                    fullWidth
                    error={Boolean(error)}
                    helperText={error && 'Campo obrigatório'}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Senha" 
                    type="text"
                    variant="outlined"
                    fullWidth
                    error={Boolean(error)}
                    helperText={error && 'Campo obrigatório'}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            variant="contained"
            type="submit"
            onClick={() => handleSubmit(onSubmit)()}
            startIcon={loading && <CircularProgress sx={{ color: 'white' }}
            size={18}
          />}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default UserDetails;
