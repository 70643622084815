/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Toolbar, Grid, TextField, Button, CircularProgress, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useBasic } from '../../../contexts/BasicContext';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

type Inputs = {
  orgOwner: string;
  userToAdd: string;
  migrateDocuments: boolean;
}

const AddUserToOrganization = () => {
  const { addUsersToOrganization } = useBasic()
  const { handleSubmit, control, reset } = useForm<Inputs>()

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);

    if (data?.orgOwner && data?.userToAdd) await addUsersToOrganization(data);

    reset({});

    setLoading(false);
  }

  return (
    <Box sx={{ margin: 1, width: '100%' }}>
      <Toolbar />
      <Typography variant="h6" style={{ margin: '0.5rem 0' }}>Adicionar usuários em organização</Typography>
      <Paper sx={{ padding: 1.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="orgOwner"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Email do owner da organização de destino" 
                  type="email"
                  variant="outlined"
                  fullWidth
                  error={Boolean(error)}
                  disabled={loading}
                  InputProps={{ endAdornment: loading && <CircularProgress size={26} /> }}
                  helperText={error && 'Campo obrigatório'}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="userToAdd"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Email do usuário a ser adicionado" 
                  type="email"
                  variant="outlined"
                  fullWidth
                  error={Boolean(error)}
                  disabled={loading}
                  InputProps={{ endAdornment: loading && <CircularProgress size={26} /> }}
                  helperText={error && 'Campo obrigatório'}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
                name="migrateDocuments"
                control={control}
                render={({ field }) => (
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />} label="Migrar documentos" />
                </FormGroup>
                )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" fullWidth onClick={() => handleSubmit(onSubmit)()} disabled={loading}>MIGRAR</Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default AddUserToOrganization;