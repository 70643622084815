import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useOrganization } from "../../../../../contexts/OrganizationContext";

import DetailsComponent from "./DetailsOrganization";
import FormsOrganization from "./FormsOrganization";
import PlanAndFeaturesOrganization from "./PlanAndFeaturesOrganization";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TabComponent: React.FC = () => {
  const { currentTabIndex, setCurrentTabIndex } = useOrganization();

  return (
    <>
      <Tabs
        value={currentTabIndex}
        onChange={(e, tabIndex) => setCurrentTabIndex(tabIndex)}
        indicatorColor="primary"
      >
        <Tab label="Detalhes" />
        <Tab label="Plano e funcionalidades" />
        <Tab label="Formulários" />
      </Tabs>

      <CustomTabPanel value={currentTabIndex} index={0}>
        <DetailsComponent />
      </CustomTabPanel>

      <CustomTabPanel value={currentTabIndex} index={1}>
        <PlanAndFeaturesOrganization />
      </CustomTabPanel>

      <CustomTabPanel value={currentTabIndex} index={2}>
        <FormsOrganization />
      </CustomTabPanel>
    </>
  );
};

export default TabComponent;
