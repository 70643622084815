import { useState } from "react";

import { Paper, TextField, Toolbar, Typography, Button, Alert, Divider } from "@mui/material";
import { Box } from "@mui/system";

import { useSnackbar } from "../../../contexts/SnackbarContext";
import dataImporterAPI from "../../../services/dataImporter"
import AsyncButton from "../../../components/AsyncButton";

import { SpreadsheetData } from "./interfaces"

const ImportData: React.FC = () => {
  const { showSnackbar } = useSnackbar();
  const [isClickedValidate, setIsClickedValidate] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<number>(0);
  const [validateInfo, setValidateInfo] = useState<SpreadsheetData | null>(null);

  // requests
  const validateOrg = async () => {
    try {
      const { data } = await dataImporterAPI.post(`/import/validate?org_id=${organizationId}`);
      return data;
    } catch (error) {
      throw new Error("Não foi possível realizar a requisição.");
    }
  };

  const importOrg = async () => {
    try {
      const { status } = await dataImporterAPI.post(`/import/?org_id=${organizationId}&user_id=${validateInfo!.user_id}`);
      return status;
    } catch (error) {
      throw new Error("Não foi possível realizar a importação.");
    }
  }

  const handleDiscart = () => {
    setOrganizationId(0);
    setIsClickedValidate(false);
    setValidateInfo(null);
  }

  const handleClickValidate = async () => {
    if (organizationId !== null && organizationId > 0) {
      try {
        const data = await validateOrg();
        setValidateInfo(data);
        setIsClickedValidate(true);
      } catch (error: any) {
        showSnackbar(error.message, "error");
      }
    } else {
      showSnackbar("Organização inválida", "error");
    }
  };

  const handleClickImport = async () => {
    try {
      const status = await importOrg();
      if (status <= 204) {
        showSnackbar("Sua requisição está sendo processada, em alguns minutos a importação será finalizada.", "success");
        handleDiscart();
      } else {
        showSnackbar("Erro ao realizar importação.", "error")
      }
    } catch (error: any) {
      showSnackbar(error.message, "error");
    }
  }

  return (
    <Box
      sx={{ margin: 1, width: "100%" }}
    >
      <Toolbar />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" style={{ margin: '0.5rem 0 1rem 0' }}>Importar dados</Typography>
      </Box>
      <Paper sx={{ padding: "0.7rem" }}>
        {isClickedValidate ? (
          <div>
            <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
              Resultado da validação dos dados
            </Typography>

            <Alert severity={validateInfo!["connected_to_apiv3"] ? "success" : "error"} sx={{ marginBottom: "0.5rem" }}>Conexão com API da Contraktor</Alert>
            <Alert severity={validateInfo!["found_drive_files"] ? "success" : "error"} sx={{ marginBottom: "0.5rem" }}>Pasta no Google Drive</Alert>
            <Alert severity={validateInfo!["found_spreadsheet"] ? "success" : "error"} sx={{ marginBottom: "0.5rem" }}>Planilha em CSV</Alert>
            <Alert severity={validateInfo!["found_header"] ? "success" : "error"} sx={{ marginBottom: "0.5rem" }}>Cabeçalho de informações da planilha</Alert>
            <Alert severity={validateInfo!["found_required_columns"] ? "success" : "error"} sx={{ marginBottom: "0.5rem" }}>Colunas obrigatórias</Alert>
            <Alert severity={validateInfo!["all_columns_valid"] ? "success" : "error"} sx={{ marginBottom: "0.5rem" }}>Colunas válidas</Alert>

            {typeof validateInfo!["error_reason"] === "string" && <Alert severity={"error"} sx={{ marginBottom: "0.5rem" }}>{validateInfo!["error_reason"]}</Alert>}

            {Array.isArray(validateInfo!["errors_while_parsing_rows"]) && <Divider sx={{margin: "2rem 0rem"}}/>}

            <ul>
              {Object.keys(validateInfo!["errors_while_parsing_rows"]).map((el) => (
                <li key={el} style={{ marginBottom: "1rem" }}><Typography variant="body1">Erro na linha: {el}</Typography>
                  <ul>
                    {validateInfo!["errors_while_parsing_rows"][Number(el)]["errors"].map((value, idx) => <li key={idx}>
                    <Typography variant="body2">{value}</Typography>
                    </li>)}
                  </ul>
                </li>
              ))}
            </ul>


            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1.7rem" }}>
              <Button style={{ marginRight: "0.5rem" }} onClick={handleDiscart}>Descartar</Button>
              {(validateInfo!["connected_to_apiv3"] &&
                validateInfo!["found_drive_files"] &&
                validateInfo!["found_spreadsheet"] &&
                validateInfo!["found_header"] &&
                validateInfo!["found_required_columns"] &&
                validateInfo!["error_reason"] === null &&
                validateInfo!["all_columns_valid"]) === true 
                  ? <AsyncButton variant="contained" onClick={handleClickImport}>
                  Importar
                </AsyncButton>
                  : <AsyncButton variant="contained" onClick={handleClickValidate}>
                  Validar
                </AsyncButton>
              }
            </div>
          </div>
        ) : (
          <>
            <Box>
              <TextField
                type="number"
                label="ID da Organização"
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                onChange={(e) => setOrganizationId(Number(e.target.value))}
              />
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1.7rem" }}>
              <AsyncButton variant="contained" onClick={handleClickValidate}>
                Validar
              </AsyncButton>
            </div>
          </>
        )}

      </Paper>
    </Box>
  );
};

export default ImportData;
