import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AsyncButton from "../../../../../components/AsyncButton";

type DialogConfirmSaveProps = {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
};

const DialogConfirmSave = ({
  open,
  onClose,
  onContinue,
}: DialogConfirmSaveProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Alterações de funcionalidades da organização</DialogTitle>
      <DialogContent>
        Deseja prosseguir com as alterações da organização?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <AsyncButton variant="contained" onClick={onContinue}>
          Salvar
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmSave;
