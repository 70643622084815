import { useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface AsyncButtonProps extends ButtonProps {
  onClick: () => void;
}

function AsyncButton({ onClick, children, disabled, ...buttonProps }: AsyncButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button {...buttonProps} onClick={handleClick} disabled={isLoading || disabled}>
      {isLoading ? <CircularProgress size={24} color="inherit" /> : children}
    </Button>
  );
}

export default AsyncButton;