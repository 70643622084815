import { createContext, useContext, useState, ReactNode } from 'react'

import api from '../../services/api'
import { useSnackbar } from '../SnackbarContext'

const AppContext = createContext<I.AppContext>({
  users: [],
  fetchUsers: () => new Promise(() => {}),
  createUser: () => new Promise(() => {}),
  deleteUser: () => new Promise(() => {}),
  login: () => new Promise(() => {}),
  logout: () => new Promise(() => {}),
})

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { showSnackbar } = useSnackbar()

  const [users, setUsers] = useState<I.AppUsers[]>([]);

  const handleFetchUsers = async () => {
    return api.get('/users')
      .then((res) => setUsers(res.data))
      .catch((err) => console.log('Error on fetch app users', err));
  }

  const handleCreateUser = async (data: Omit<I.AppUsers, 'id'>) => {
    return api.post('/users', data)
      .then((res) => {
        handleFetchUsers()
        showSnackbar('Usuário adicionado com sucesso.', 'success')
      })
      .catch((err) => {
        console.log('Error on create app user', err);
        showSnackbar('Erro ao adicionar usuário.', 'error');
      });
  }

  const handleDeleteUser = async (id: string) => {
    return api.delete(`/users/${id}`)
      .then((res) => {
        handleFetchUsers()
        showSnackbar('Usuário deletado com sucesso.', 'success')
      })
      .catch((err) => {
        console.log('Error on delete app user', err)
        showSnackbar('Erro ao deletar usuário.', 'error');
      });
  }

  const handleLogin = (data: I.LoginData): Promise<{ error?: boolean, success?: boolean }> => {
    return api.post('/auth/login', data)
      .then(({ data }) => {
        if (data?.accessToken) {
          localStorage.setItem("SA_TOKEN", data.accessToken);

          api.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;

          return { success: true }
        }

        return { error: true }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          showSnackbar(err.response.data.message, 'error');

          return { error: true }
        }

        showSnackbar('Erro ao realizar a requisição, entre em contato a TI', 'error');

        return { error: true }
      })
  }

  const handleLogout = async () => {
    localStorage.removeItem('SA_TOKEN');

    delete api.defaults.headers.common["Authorization"];
  }

  return (
    <AppContext.Provider
      value={{
        users,
        fetchUsers: handleFetchUsers,
        createUser: handleCreateUser,
        deleteUser: handleDeleteUser,
        login: handleLogin,
        logout: handleLogout
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useApp = (): I.AppContext => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useApp must be used within a <AppProvider />')
  }

  return context
}

export default AppContext
