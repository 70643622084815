export const formatDate = (date: string | null) => {
  if (date === null) return "";

  const _date = new Date(date);

  const formated_date = new Intl.DateTimeFormat("pt-br", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(_date);
  return formated_date;
};
