import React, { createContext, useState, ReactNode, useContext } from "react";
import { useSnackbar } from "../SnackbarContext";
import api from "../../services/api";

const OrganizationContext = createContext<I.OrganizationContext | null>(null);

const OrganizationProvider = ({ children }: { children: ReactNode }) => {
  const { showSnackbar } = useSnackbar();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [organization, setOrganization] = useState<I.Organization>();

  // detalhes
  const [organizationName, setOrganizationName] = useState<string>("");
  const [isOrganizationActive, setIsOrganizationActive] =
    useState<boolean>(false);
  const [logoOrganization, setLogoOrganization] = useState<string>("");
  const [availableEngines, setAvailableEngines] = useState<I.engine[]>([]);
  const [contractSeqNumber, setContractSeqNumber] = useState<number | string>(
    1
  );
  const [contractSeqYear, setContractSeqYear] = useState<number | string>(1);

  // planos e funcionalidades
  const [plansList, setPlansList] = useState<I.Plan[]>([]);
  const [planName, setPlanName] = useState<string>("");
  const [price, setPrice] = useState<number | string>(0);
  const [priceUser, setPriceUser] = useState<number | string>(0);
  const [whatsAppMonthlyQuota, setWhatsAppMonthlyQuota] = useState<string | number>(0);

  const [formReviewFlow, setFormReviewFlow] = useState<boolean>(false);
  const [documentHistory, setDocumentHistory] = useState<boolean>(false);
  const [redlining, setRedlining] = useState<boolean>(false);
  const [sharelink, setSharelink] = useState<boolean>(false);
  const [whatsappLink, setWhatsappLink] = useState<boolean>(false);
  const [whatsappNotification, setWhatsappNotification] =
    useState<boolean>(false);
  const [proofReminder, setProofReminder] = useState<boolean>(false);
  const [proofReminderFrequency, setProofReminderFrequency] = useState<
    number | string
  >(0);
  const [signatureWith2FA, setSignatureWith2FA] = useState<boolean>(false);
  const [signatureWithLoginPassword, setSignatureWithLoginPassword] = useState<boolean>(false);
  const [anonymizeProofSubjectsInfo, setAnonymizeProofSubjectsInfo] = useState<boolean>(false);

  // formulários
  const [forms, setForms] = useState<any>([]);
  const [searchTextForms, setSearchTextForms] = useState<string>("");

  // modais
  const [isDialogConfirmSaveOpen, setIsDialogConfirmSaveOpen] =
    useState<boolean>(false);
  const [isDialogDiscartChangesOpen, setIsDialogDiscartChangesOpen] =
    useState<boolean>(false);

  const [isFetchingOrganization, setIsFetchingOrganization] =
    useState<boolean>(true);

  const fetchOrganization = async (id: string | number) => {
    try {
      setIsFetchingOrganization(true);
      const { data } = await api.get(`/advanced/organization/${id}`);

      setOrganization(data);

      setIsOrganizationActive(data.active);
      setOrganizationName(data.name);
      setLogoOrganization(data.logo_url);
      setAvailableEngines(data.available_proof_engines);
      setContractSeqNumber(data.contract_seq_number);
      setContractSeqYear(data.contract_seq_year);

      setPlanName(data.plan_name);
      setPrice(data.price);
      setPriceUser(data.price_user);
      setWhatsAppMonthlyQuota(data.whatsapp_monthly_quota);

      setDocumentHistory(data.document_history);
      setFormReviewFlow(data.form_review_flow);
      setRedlining(data.redlining);
      setSharelink(data.sharelink);
      setWhatsappLink(data.whatsapp_link);
      setProofReminder(data.proof_reminder);
      setProofReminderFrequency(data.proof_reminder_frequency);
      setWhatsappNotification(data.whatsapp_notification);
      setIsFetchingOrganization(false);
      setSignatureWith2FA(data.signature_with_2fa);
      setSignatureWithLoginPassword(data.signature_with_login_password);
      setAnonymizeProofSubjectsInfo(data.anonymize_proof_subjects_info);
    } catch (error) {
      setIsFetchingOrganization(false);
      showSnackbar("Erro ao carregar informações da organização", "error");
    }
  };

  const getPlansList = async () => {
    try {
      const { data } = await api.get("/advanced/plans");
      setPlansList(data);
    } catch (error) {
      showSnackbar("Erro ao carregar lista de planos.", "error");
    }
  };

  const getForms = async (id: string | number) => {
    try {
      const { data } = await api.get(`/advanced/organization/${id}/forms`);
      setForms(data);
    } catch (error) {
      showSnackbar("Erro ao carregar informações dos formulários.", "error");
    }
  };

  const updateForm = async (id: string, custom: boolean) => {
    try {
      await api.put(`/advanced/organization/${id}/forms`, {
        custom: custom,
      });
      await getForms(organization!.id!);

      showSnackbar("Formulário atualizado com sucesso.", "success");
    } catch (error) {
      showSnackbar("Erro ao atualizar informação do formulário.", "error");
    }
  };

  const updateOrganization = async (id: string | number) => {
    try {
      await api.patch(`/advanced/organization/${id}`, {
        active: isOrganizationActive,
        name: organizationName,
        logo_url: logoOrganization,
        available_proof_engines: availableEngines,
        contract_seq_number: contractSeqNumber,
        contract_seq_year: contractSeqYear,
      });
      showSnackbar(`Organização atualizada com sucesso!`, "success");
      fetchOrganization(id);
    } catch (error) {
      showSnackbar("Não foi possível ativar/desativar organização.", "error");
    }
  };

  const updateOrganizationPlan = async (id: string | number) => {
    const plan = plansList.find((el: any) => el.name === planName);

    const body = {
      plan_id: plan?.id,
      price: price,
      price_user: priceUser,
      document_history: documentHistory,
      form_review_flow: formReviewFlow,
      redlining: redlining,
      sharelink: sharelink,
      whatsapp_link: whatsappLink,
      proof_reminder: proofReminder,
      proof_reminder_frequency: proofReminderFrequency,
      whatsapp_notification: whatsappNotification,
      whatsapp_monthly_quota: whatsAppMonthlyQuota,
      signature_with_2fa: signatureWith2FA,
      signature_with_login_password: signatureWithLoginPassword,
      anonymize_proof_subjects_info: anonymizeProofSubjectsInfo
    };

    try {
      await api.put(`/advanced/organization/${id}/plan`, body);
      showSnackbar(
        "Funcionalidades da organização foram alteradas com sucesso.",
        "success"
      );
      setIsDialogConfirmSaveOpen(false);
      fetchOrganization(id);
    } catch (error) {
      showSnackbar(
        "Não foi possível alterar funcionalidades da organização.",
        "error"
      );
    }
  };

  const resetState = () => {
    setIsOrganizationActive(organization!.active);
    setOrganizationName(organization!.name);
    setLogoOrganization(organization!.logo_url);
    setAvailableEngines(organization!.available_proof_engines);
    setContractSeqNumber(organization!.contract_seq_number);
    setContractSeqYear(organization!.contract_seq_year);

    setPlanName(organization!.plan_name);
    setPrice(organization!.price);
    setPriceUser(organization!.price_user);
    setWhatsAppMonthlyQuota(organization!.whatsapp_monthly_quota);

    setDocumentHistory(organization!.document_history);
    setFormReviewFlow(organization!.form_review_flow);
    setRedlining(organization!.redlining);
    setSharelink(organization!.sharelink);
    setWhatsappLink(organization!.whatsapp_link);
    setProofReminder(organization!.proof_reminder);
    setProofReminderFrequency(organization!.proof_reminder_frequency);
    setWhatsappNotification(organization!.whatsapp_notification);
    setSignatureWith2FA(organization!.signature_with_2fa);
    setSignatureWithLoginPassword(organization!.signature_with_login_password);
    setAnonymizeProofSubjectsInfo(organization!.anonymize_proof_subjects_info);
  };

  return (
    <OrganizationContext.Provider
      value={{
        currentTabIndex,
        setCurrentTabIndex,
        organizationName,
        setOrganizationName,
        isOrganizationActive,
        setIsOrganizationActive,
        logoOrganization,
        setLogoOrganization,
        availableEngines,
        setAvailableEngines,
        contractSeqNumber,
        setContractSeqNumber,
        contractSeqYear,
        setContractSeqYear,
        planName,
        setPlanName,
        price,
        setPrice,
        priceUser,
        setPriceUser,
        whatsAppMonthlyQuota, 
        setWhatsAppMonthlyQuota,
        documentHistory,
        setDocumentHistory,
        formReviewFlow,
        setFormReviewFlow,
        redlining,
        setRedlining,
        sharelink,
        setSharelink,
        whatsappLink,
        setWhatsappLink,
        proofReminder,
        setProofReminder,
        proofReminderFrequency,
        setProofReminderFrequency,
        whatsappNotification,
        setWhatsappNotification,
        forms,
        setForms,
        searchTextForms,
        setSearchTextForms,
        isDialogConfirmSaveOpen,
        setIsDialogConfirmSaveOpen,
        isDialogDiscartChangesOpen,
        setIsDialogDiscartChangesOpen,
        organization: organization!,
        fetchOrganization,
        updateOrganization,
        updateOrganizationPlan,
        resetState,
        getPlansList,
        getForms,
        updateForm,
        isFetchingOrganization,
        plansList,
        signatureWith2FA,
        setSignatureWith2FA,
        signatureWithLoginPassword,
        setSignatureWithLoginPassword,
        anonymizeProofSubjectsInfo,
        setAnonymizeProofSubjectsInfo,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error("There is no context for useOrganization");
  }

  return context;
};

export { OrganizationContext, OrganizationProvider };
