/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import Toolbar from '@mui/material/Toolbar';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import TablePaginationActions from '../../../components/TablePaginationActions';
import { useApp } from '../../../contexts/AppContext';
import { CircularProgress, Icon } from '@mui/material';
import UserDetails from './shared/UserDetails';

const Users = () => {
  const { fetchUsers, deleteUser, users } = useApp();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = async () => {
    await fetchUsers()
    
    setLoading(false)
  }

  const handleDeleteUser = async () => {
    if (selectedUserId) {
      handleClose()
      setLoading(true)

      await deleteUser(selectedUserId)

      setLoading(false)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, userId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUserId('');
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  if (loading) {
    return (
      <Box sx={{ margin: 1, width: '100%' }}>
        <Toolbar />
        <Typography variant="h6" style={{ margin: '0.5rem 0 1rem 0' }}>Usuários do SuperADMIN</Typography>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <UserDetails open={userDetailsModal} onClose={() => setUserDetailsModal(false)} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDeleteUser}>Deletar</MenuItem>
      </Menu>
      <Box sx={{ margin: 1, width: '100%' }}>
        <Toolbar />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
          <Typography variant="h6">Usuários do SuperADMIN</Typography>
          <Button onClick={() => setUserDetailsModal(true)} variant="contained">ADICIONAR USUÁRIO</Button>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="right">E-mail</TableCell>
                <TableCell align="right">Ativo</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : users
              ).map((row, key) => (
                <TableRow key={key}>
                  <TableCell>
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.email}
                  </TableCell>
                  <TableCell align="right">
                    {row.active ? <Chip label="Ativo" size="small" color="primary" /> : <Chip label="Inativo" size="small" color="error" />}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={(e) => handleClick(e, row.id)}>
                      <Icon>settings</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default Users;
