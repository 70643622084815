import React from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import AdvancedUsers from './pages/Advanced/Users';
import BasicExportPayments from './pages/Basic/ExportPayments';
import BasicForceSignature from './pages/Basic/ForceSignature';
import BasicAddUser from './pages/Basic/AddUserToOrganization';
import Users from './pages/App/Users';
import Login from './pages/Login';

import ProtectedRoute from './components/ProtectedRoute';
import ExportData from './pages/Advanced/ExportData';
import Organizations from './pages/Advanced/Organizations';
import Organization from './pages/Advanced/Organization';
import ImportData from './pages/Advanced/ImportData';
import BasicExportData from "./pages/Basic/BasicExportData";
import ForceMigrate from './pages/Advanced/ForceMigrate';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><div>Home</div></ProtectedRoute>} />
      <Route path="basic">
        <Route path="export-payments" element={<ProtectedRoute><BasicExportPayments /></ProtectedRoute>} />
        <Route path="users" element={<ProtectedRoute><div>Users</div></ProtectedRoute>} />
        <Route path="export-data" element={<ProtectedRoute><BasicExportData /></ProtectedRoute>} />
        <Route path="force-signature" element={<ProtectedRoute><BasicForceSignature /></ProtectedRoute>} />
        <Route path="add-user-to-organization" element={<ProtectedRoute><BasicAddUser /></ProtectedRoute>} />
      </Route>
      <Route path="advanced">
        <Route path="users" element={<ProtectedRoute><AdvancedUsers /></ProtectedRoute>} />
        <Route path="import-data" element={<ProtectedRoute><ImportData /></ProtectedRoute>}/>
        <Route path="export-data" element={<ProtectedRoute><ExportData /></ProtectedRoute>}/>
        <Route path="organizations" element={<ProtectedRoute><Organizations /></ProtectedRoute>}/>
        <Route path="organization/:id" element={<ProtectedRoute><Organization /></ProtectedRoute>}/>
        <Route path="force-migrate" element={<ProtectedRoute><ForceMigrate /></ProtectedRoute>} />
      </Route>
      <Route path="users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
      <Route path="login" element={<Login />} />
    </Routes>
  );
}

export default AppRoutes;