/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Toolbar, Grid, TextField, Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useBasic } from '../../../contexts/BasicContext';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

type Inputs = {
  id: string
}

const ForceSignature = () => {
  const { forceDocumentSignature } = useBasic()
  const { handleSubmit, control, reset } = useForm<Inputs>()

  const [loading, setLoading] = useState(false);
  const [, setDocument] = useState<I.BasicDocument>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);

    if (data?.id) {
      const document = await forceDocumentSignature(data.id);
      
      reset({})

      if (document) {
        setDocument(document);
      }
    }

    setLoading(false);
  }

  return (
    <Box sx={{ margin: 1, width: '100%' }}>
      <Toolbar />
      <Typography variant="h6" style={{ margin: '0.5rem 0' }}>Documento sem comprovante</Typography>
      <Paper sx={{ padding: 1.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="id"
              rules={{ required: true }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="ID do Documento ou Token para assinatura" 
                  type="id"
                  variant="outlined"
                  fullWidth
                  error={Boolean(error)}
                  disabled={loading}
                  InputProps={{ endAdornment: loading && <CircularProgress size={26} /> }}
                  helperText={error && 'Campo obrigatório'}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" fullWidth onClick={() => handleSubmit(onSubmit)()} disabled={loading}>ENVIAR</Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default ForceSignature;