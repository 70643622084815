import Providers from './components/Providers';
import Routes from './routes';

const App = () => {
  return (
    <Providers>
      <Routes />
    </Providers>
  );
}

export default App;
