import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { useSnackbar } from "../../../contexts/SnackbarContext";

interface IDialogNewUrlExport {
  open: boolean;
  onClose: () => void;
  link: string;
}

const DialogNewUrlExport: React.FC<IDialogNewUrlExport> = ({
  open,
  onClose,
  link,
}) => {
	const { showSnackbar } = useSnackbar() 


  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
		showSnackbar("Link copiado para área de transferência!", "success");
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Novo link gerado!</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", width: "35vw" }}>
            <FormControl
              fullWidth
              variant="standard"
              style={{ padding: "0px 5px" }}
            >
              <TextField
                id="linkTextField"
                variant="outlined"
                value={link}
                disabled
              />
            </FormControl>
            <Button
              variant="contained"
              onClick={copyToClipboard}
            >
              Copiar
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogNewUrlExport;
