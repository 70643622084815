import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Toolbar,
  Typography,
  Button,
  Paper,
  IconButton,
  Box,
  TextField,
  Icon,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DialogConfirmSave from "./components/DialogConfirmSave";
import DialogDiscardChanges from "./components/DialogDiscartChanges";

import {
  OrganizationProvider,
  useOrganization,
} from "../../../contexts/OrganizationContext";
import TabComponent from "./components/TabComponent";

type OrganizationParams = { id: string };

const Organization: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<OrganizationParams>();
  const { id } = params;

  const {
    isDialogConfirmSaveOpen,
    setIsDialogConfirmSaveOpen,
    isDialogDiscartChangesOpen,
    setIsDialogDiscartChangesOpen,
    organization,
    fetchOrganization,
    updateOrganization,
    updateOrganizationPlan,
    resetState,
    isFetchingOrganization,
    getForms,
    getPlansList,
    currentTabIndex,
    setSearchTextForms,
  } = useOrganization();

  const handleUpdateOrganization = async () => {
    await updateOrganizationPlan(id!);
    await updateOrganization(id!);
    handleCloseDialogSave();
  };

  const handleCloseDialogSave = () => {
    setIsDialogConfirmSaveOpen(false);
  };

  const handleClickSave = () => {
    setIsDialogConfirmSaveOpen(true);
  };

  const handleClickDiscartChangesButton = () => {
    setIsDialogDiscartChangesOpen(true);
  };

  const handleDiscartChanges = () => {
    resetState();
    setIsDialogDiscartChangesOpen(false);
  };

  const handleCloseDiscartChanges = () => {
    setIsDialogDiscartChangesOpen(false);
  };

  useEffect(() => {
    fetchOrganization(id!);
    getForms(id!);
    getPlansList();
    // eslint-disable-next-line
  }, []);

  if (!organization) {
    return <Box sx={{ margin: 1, width: "100%" }}>Loading...</Box>;
  }

  return (
    <Box sx={{ margin: 1, width: "100%" }} className="main-box-organization">
      <DialogConfirmSave
        open={isDialogConfirmSaveOpen}
        onClose={handleCloseDialogSave}
        onContinue={handleUpdateOrganization}
      />
      <DialogDiscardChanges
        open={isDialogDiscartChangesOpen}
        onClose={handleCloseDiscartChanges}
        onContinue={handleDiscartChanges}
      />

      <Toolbar />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0.5rem 0",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(`/advanced/organizations`)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{organization.name}</Typography>
        </div>
        <div>
          {currentTabIndex === 2 ? (
            <TextField
              size="small"
              placeholder="Pesquisar por ID"
              onChange={(e) => setSearchTextForms(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Icon color="primary" sx={{ marginRight: 1 }}>
                    search
                  </Icon>
                ),
              }}
            />
          ) : (
            <>
              <Button onClick={handleClickDiscartChangesButton}>
                Descartar
              </Button>
              <Button
                variant="contained"
                onClick={handleClickSave}
                style={{ marginLeft: "1rem" }}
              >
                Salvar
              </Button>
            </>
          )}
        </div>
      </Box>

      <Paper sx={{ padding: 1.5 }}>
        {isFetchingOrganization ? <CircularProgress /> : <TabComponent />}
      </Paper>
    </Box>
  );
};

const OrganizationContainer: React.FC = () => (
  <OrganizationProvider>
    <Organization />
  </OrganizationProvider>
);

export default OrganizationContainer;
