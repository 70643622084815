import React from 'react';
import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { AdvancedProvider } from '../../contexts/AdvancedContext';
import { AppProvider } from '../../contexts/AppContext';
import { BasicProvider } from '../../contexts/BasicContext';
import { SnackbarProvider } from '../../contexts/SnackbarContext';

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <SnackbarProvider>
          <AppProvider>
            <AdvancedProvider>
              <BasicProvider>
                {children}
              </BasicProvider>
            </AdvancedProvider>
          </AppProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default Providers;